<template>
  <div class="flex-grow">
    <!-- info เป็น 0 แสดงหน้าเพิ่มบัญชี -->
    <div class="edit-data" v-if="!hasBank">
      <h1>บัญชีรับเงินรางวัล</h1>
      <Form @submit="updateBank">
        <div class="form-data">
          <div class="row mb-3">
            <label for="bank_name" class="col-sm-2 col-form-label">ธนาคาร</label>
            <div class="col-sm-10">
              <Field v-slot="{ value }" name="bank_provider_id" as="select" class="form-control">
                <option value="" disabled>Select a bank</option>
                <option v-for="bank in bankType" :key="bank" :value="bank.id" :selected="value && value.includes(bank)">{{ bank.bank_fullname }}</option>
              </Field>
            </div>
          </div>
          <div class="row mb-3">
            <label for="account_name" class="col-sm-2 col-form-label">ชื่อบัญชี</label>
            <div class="col-sm-10">
              <Field type="text" class="form-control" name="bank_account" />
            </div>
          </div>
          <div class="row mb-3">
            <label for="account_number" class="col-sm-2 col-form-label">เลขบัญชี</label>
            <div class="col-sm-10">
              <Field type="text" class="form-control" name="bank_number" />
            </div>
          </div>
          <button class="btn cancel" type="reset">ยกเลิก</button>
          <button class="btn save" type="submit">บันทึก</button>
        </div>
      </Form>
    </div>
    <!-- info เป็น 1 แสดงบัญชี -->
    <div class="data" v-else>
      <h1>บัญชีรับเงินรางวัล</h1>
      <Form @submit="updateBank">
        <div class="form-data">
          <div class="row mb-3">
            <label for="bank_name" class="col-sm-2 col-form-label">ธนาคาร</label>
            <div class="col-sm-10">
              {{ content.bank.bank_provider.bank_fullname }}
              <Field type="text" class="form-control" name="bank_provider_id" :value="content.bank.bank_provider_id" hidden />
            </div>
          </div>
          <div class="row mb-3">
            <label for="bank_account" class="col-sm-2 col-form-label">ชื่อบัญชี</label>
            <div class="col-sm-10">
              <Field type="text" class="form-control" name="bank_account" :value="content.bank.bank_account" />
            </div>
          </div>
          <div class="row mb-3">
            <label for="account_number" class="col-sm-2 col-form-label">เลขบัญชี</label>
            <div class="col-sm-10">
              <Field type="text" class="form-control" name="bank_number" :value="content.bank.bank_number" />
            </div>
          </div>
          <button class="btn save">แก้ไข</button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import UserService from '../services/user.service'
import { Form, Field } from 'vee-validate'
export default {
  name: 'Receipt',
  components: {
    Form,
    Field
  },
  data() {
    return {
      hasBank: false,
      content: {},
      bankType: {}
    }
  },
  async created() {
    if (!this.$store.state.auth.user) {
      this.$router.push('/login')
    }
    try {
      const response = await UserService.getProfile()
      if (response.user.bank != null) {
        this.content = response.user
        this.hasBank = true
      } else {
        const bankType = await UserService.getBanktype()
        this.bankType = bankType.data
      }
    } catch (error) {
      this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    }
  },
  methods: {
    async updateBank(elem) {
      try {
        const response = await UserService.updateBank(elem)
        this.$toast.success(response.data.message)
        const responses = await UserService.getProfile()
        this.content = await responses.user
        this.hasBank = true
      } catch (error) {
        this.$toast.success(error)
      }
    }
  }
}
</script>
<style scoped>
.data,
.edit-data {
}
.data h1,
.edit-data h1 {
  padding: 1%;
  font-size: 30px;
  color: rgb(107, 107, 107);
  text-align: left;
}
input {
  background-color: #b7e9cb;
}
select {
  background-color: #b7e9cb;
}
.data button,
.edit-data button {
  width: 300px;
  border-radius: 35px;
  margin-top: 2rem;
  margin-left: 15px;
  margin-right: 15px;
  color: #fff;
}
.cancel {
  background-color: #fb5c62;
}
.save {
  background-color: #63c289;
}
.form-data {
  max-width: 850px;
  background: whitesmoke;
  border-radius: 35px;
  box-sizing: border-box;
  box-shadow: 1px 2px 1px 2px lightgray;
  padding: 55px;
  color: rgb(106, 211, 162);
}
</style>
